import React from "react";

const handleDragStart = (e) => e.preventDefault();

const TeamMember = ({name, imgParam, description}) => {
    return (
        <div>
            <img
                className={'team-img'}
                src={require('../../assets/imgs/team/' + imgParam)} onDragStart={handleDragStart} role="presentation"
                alt={'team-1'}
            />
            <h3
                style={{
                    marginTop: '5px',
                }}
            >
                {
                    name
                }
            </h3>

            <h5
                style={{
                    marginTop: '5px',
                }}
            >
                {
                    description
                }
            </h5>
        </div>
    );
}

export default TeamMember;

import React from "react";
import "./insight.css";
import {Box, Container, useMediaQuery} from "@mui/material";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const handleDragStart = (e) => e.preventDefault();

const responsiveCarousel = {
    0: {items: 1},
    899: {items: 1},
    900: {items: 2},
    1200: {items: 3},
    1400: {items: 4},
}

const items = [
    <div
        className={'insight-img-div'}
    >
        <img
            className={'insight-img'}
            src={require('../../assets/imgs/zoorbis/z-1.png')} onDragStart={handleDragStart} role="presentation"
            alt={'zoorbis-1'}
        />
    </div>,
    <div
        className={'insight-img-div'}
    >
        <img
            className={'insight-img'}
            src={require('../../assets/imgs/zoorbis/z-5.png')} onDragStart={handleDragStart} role="presentation"
            alt={'zoorbis-5'}
        />
    </div>,
    <div
        className={'insight-img-div'}
    >
        <img
            className={'insight-img'}
            src={require('../../assets/imgs/zoorbis/z-9.png')} onDragStart={handleDragStart} role="presentation"
            alt={'zoorbis-9'}
        />
    </div>,
    <div
        className={'insight-img-div'}
    >
        <img
            className={'insight-img'}
            src={require('../../assets/imgs/zoorbis/z-13.png')} onDragStart={handleDragStart} role="presentation"
            alt={'zoorbis-13'}
        />
    </div>,
    <div
        className={'insight-img-div'}
    >
        <img
            className={'insight-img'}
            src={require('../../assets/imgs/zoorbis/z-2.png')} onDragStart={handleDragStart} role="presentation"
            alt={'zoorbis-2'}
        />
    </div>,
    <div
        className={'insight-img-div'}
    >
        <img
            className={'insight-img'}
            src={require('../../assets/imgs/zoorbis/z-6.png')} onDragStart={handleDragStart} role="presentation"
            alt={'zoorbis-6'}
        />
    </div>,
    <div
        className={'insight-img-div'}
    >
        <img
            className={'insight-img'}
            src={require('../../assets/imgs/zoorbis/z-10.png')} onDragStart={handleDragStart} role="presentation"
            alt={'zoorbis-10'}
        />
    </div>,
    <div
        className={'insight-img-div'}
    >
        <img
            className={'insight-img'}
            src={require('../../assets/imgs/zoorbis/z-14.png')} onDragStart={handleDragStart} role="presentation"
            alt={'zoorbis-14'}
        />
    </div>,
    <div
        className={'insight-img-div'}
    >
        <img
            className={'insight-img'}
            src={require('../../assets/imgs/zoorbis/z-3.png')} onDragStart={handleDragStart} role="presentation"
            alt={'zoorbis-3'}
        />
    </div>,
    <div
        className={'insight-img-div'}
    >
        <img
            className={'insight-img'}
            src={require('../../assets/imgs/zoorbis/z-7.png')} onDragStart={handleDragStart} role="presentation"
            alt={'zoorbis-7'}
        />
    </div>,
    <div
        className={'insight-img-div'}
    >
        <img
            className={'insight-img'}
            src={require('../../assets/imgs/zoorbis/z-11.png')} onDragStart={handleDragStart} role="presentation"
            alt={'zoorbis-11'}
        />
    </div>,
    <div
        className={'insight-img-div'}
    >
        <img
            className={'insight-img'}
            src={require('../../assets/imgs/zoorbis/z-15.png')} onDragStart={handleDragStart} role="presentation"
            alt={'zoorbis-15'}
        />
    </div>,
    <div
        className={'insight-img-div'}
    >
        <img
            className={'insight-img'}
            src={require('../../assets/imgs/zoorbis/z-4.png')} onDragStart={handleDragStart} role="presentation"
            alt={'zoorbis-4'}
        />
    </div>,
    <div
        className={'insight-img-div'}
    >
        <img
            className={'insight-img'}
            src={require('../../assets/imgs/zoorbis/z-8.png')} onDragStart={handleDragStart} role="presentation"
            alt={'zoorbis-8'}
        />
    </div>,
    <div
        className={'insight-img-div'}
    >
        <img
            className={'insight-img'}
            src={require('../../assets/imgs/zoorbis/z-12.png')} onDragStart={handleDragStart} role="presentation"
            alt={'zoorbis-12'}
        />
    </div>,
    <div
        className={'insight-img-div'}
    >
        <img
            className={'insight-img'}
            src={require('../../assets/imgs/zoorbis/z-16.png')} onDragStart={handleDragStart} role="presentation"
            alt={'zoorbis-16'}
        />
    </div>,
];

const FirstInsight = () => {

    const sm = useMediaQuery('(max-width:899px)');

    return (
        <Box
            className={'insight-container'}
            style={{
                padding: sm ? '50px 20px 50px 20px' : '50px 0px 50px 0px',
            }}
        >
            <div
                className={'insight-line-1'}
            />

            <Container
                maxWidth={'lg'}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <img
                    src={require('../../assets/imgs/first-peek/tarjetas.png')}
                    alt={'tarjetas'}
                    style={{
                        width: 'auto',
                        height: '180px',
                        marginTop: '-140px',
                        marginBottom: '30px',
                        maxWidth: sm ? '150%' : 'auto',
                        objectFit: 'contain',
                    }}
                />
                <h1
                    style={{
                        fontSize: '20px',
                        fontWeight: 'bold',
                        margin: 0,
                        marginBottom: '50px',
                        color: 'white',
                        letterSpacing: '2px',
                    }}
                >
                    ZOORBIS FIRST PEEK
                </h1>

                <AliceCarousel
                    items={items}
                    responsive={responsiveCarousel}
                    mouseTracking={sm}
                    autoPlay={true}
                    autoPlayInterval={3000}
                    infinite
                />
            </Container>
        </Box>
    );
}

export default FirstInsight;

import React from "react";
import "./token.css";

const TokenComponent = ({title, subtitle, tokenImg, tokenName, tokenDescription}) => {
    return(
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: 250,
                borderRadius: '10px',
                margin: 'auto',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#E99F00',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    borderTopLeftRadius: '10px',
                    borderTopRightRadius: '10px',
                }}
            >
                <h1
                    style={{
                        fontSize: '30px',
                        fontWeight: '600',
                        margin: 0,
                    }}
                >
                    {title}
                </h1>
                <h3
                    style={{
                        fontSize: '20px',
                        margin: '0px',
                        fontWeight: '400',
                    }}
                >
                    {subtitle}
                </h3>
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#FFFFFF',
                    color: 'black',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    borderBottomLeftRadius: '10px',
                    borderBottomRightRadius: '10px',
                }}
            >
                <img
                    src={tokenImg}
                    alt={'token'}
                    style={{
                        width: 'auto',
                        height: '150px',
                    }}
                />
                <h1
                    style={{
                        fontSize: '30px',
                        fontWeight: '600',
                        margin: 0,
                    }}
                >
                    {tokenName}
                </h1>
                <h3
                    style={{
                        fontSize: '20px',
                        margin: '0px',
                        fontWeight: '400',
                    }}
                >
                    {tokenDescription}
                </h3>
            </div>
        </div>
    );
}

export default TokenComponent;

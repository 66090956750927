import react, { useState, useEffect } from "react";
import "./intro.css";
import {
  Box,
  Button,
  InputBase,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 2,
    position: "relative",
    backgroundColor: "none",
    border: "1px solid #ced4da",
    fontSize: 14,
    width: "100%",
    padding: "2px 4px 3px 4px",
    color: "#FFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: "none",
      borderColor: "#FFF",
    },
  },
}));

const IntroInfo = () => {
  const hideErc20Title = useMediaQuery("(max-width:420px)");

  return (
    <div className={"intro-container"}>
      <img
        style={{
          maxWidth: "100%",
          maxHeight: "550px",
        }}
        src={require("../../assets/imgs/intro/zoorbis-intro.png")}
        alt={"NFTs"}
      />

      <Box className={"intro-txt"}>
        <span>
          Contract Address: 0x4833adDc2064FCB2a2eFde54d1FECE7111C45e6c
        </span>
      </Box>

      <Button
        variant="contained"
        disableElevation={true}
        disabled={true}
        style={{
          marginTop: "30px",
          marginBottom: "30px",
          backgroundColor: "#F0B12B",
          color: "#FFF",
          borderRadius: "10px",
          fontWeight: "400",
          width: "250px",
          padding: "5px 25px 5px 25px",
          fontSize: "1.1rem",
        }}
        onClick={() => {
          //window.open('https://snowtrace.io/token/0x4833adDc2064FCB2a2eFde54d1FECE7111C45e6c', '_blank');
          window.open("https://staking.zoorbis.com/", "_blank");
        }}
      >
        Trade coming soon
      </Button>
    </div>
  );
};

export default IntroInfo;

import React from "react";
import './video-display.css'
import {Box, useMediaQuery} from "@mui/material";
import ReactPlayer from "react-player";
import VideoZoorbis from "../../assets/videos/zoorbis-video.mp4";
import ThumbnailZoorbis from "../../assets/imgs/thumbnail-video.png";

const VideoPlayer = () => {

    const sm = useMediaQuery('(max-width:899px)');

    return (
        <div
            className={"video-container"}
        >
            <Box
                className={'video-iframe'}
                style={{
                    position: 'relative',
                }}
            >
                <img
                    style={{
                        maxWidth: '100%',
                        maxHeight: '405px',
                    }}
                    src={require("../../assets/imgs/marco-video.png")}
                    alt={'NFTs'}
                />
                <ReactPlayer
                    className={'video-player'}
                    url={VideoZoorbis}
                    light={ThumbnailZoorbis}
                    loop controls
                />
            </Box>
        </div>
    );
}

export default VideoPlayer;

import React from "react";
import "./support.css";
import { Container, Grid, useMediaQuery } from "@mui/material";

const Support = () => {
  const sm = useMediaQuery("(max-width:899px)");

  return (
    <div className={"support-container"}>
      <Container
        maxWidth={"lg"}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1
          style={{
            fontSize: sm ? "30px" : "40px",
            fontWeight: "bold",
            margin: "50px 0 0 0",
          }}
        >
          SUPPORT IN
        </h1>

        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            marginTop: 50,
            marginBottom: 50,
          }}
        >
          <Grid
            item
            sm={12}
            md={3}
            style={{
              marginTop: sm ? "50px" : "0px",
            }}
          >
            <img
              style={{ width: "15rem" }}
              src={require("../../assets/imgs/support/polygon-logo.png")}
              alt={"support-2"}
            />
          </Grid>
          {/*                     <Grid
                                            item sm={12} md={3}
                                            style={{
                                                width: '100%',
                                                marginTop: sm ? '50px' : '0px',
                                            }}
                                        >
                                            <img
                                                src={require('../../assets/imgs/support/support-2.png')}
                                                alt={'support-2'}
                                            />
                                        </Grid>

                    <Grid
                        item sm={12} md={3}
                        style={{
                            width: '100%',
                            marginTop: sm ? '50px' : '0px',
                        }}
                    >
                        <img
                            src={require('../../assets/imgs/support/support-3.png')}
                            alt={'support-3'}
                        />
                    </Grid> */}
        </Grid>
      </Container>
    </div>
  );
};

export default Support;

import react, { useState, useEffect } from 'react';
import './community.css';
import { Box, Button, Container, Grid, useMediaQuery } from '@mui/material';
import moment from 'moment';

const CommunityHub = () => {
  const sm = useMediaQuery('(max-width:899px)');

  const [days, setDays] = useState('0');
  const [hours, setHours] = useState('0');
  const [minutes, setMinutes] = useState('0');
  const [seconds, setSeconds] = useState('0');

  const getTimeRemaining = () => {
    let dateSale = moment();
    dateSale.isoWeekday(7);
    dateSale.hour(0);
    dateSale.minute(0);
    dateSale.second(0);
    dateSale.isoWeekYear(2023);
    dateSale.isoWeek(30);

    const intervalRef = setInterval(() => {
      const now = moment();
      const diff = dateSale.diff(now);
      if (diff > 0) {
        const duration = moment.duration(diff);
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();
        setDays(days.toString());
        setHours(hours.toString());
        setMinutes(minutes.toString());
        setSeconds(seconds.toString());
      } else {
        clearInterval(intervalRef);
      }
    }, 1000);
  };

  useEffect(() => {
    getTimeRemaining();
  }, []);

  const medallas = [
    {
      source: require('../../assets/imgs/community/med-1.png'),
    },
    {
      source: require('../../assets/imgs/community/med-2.png'),
    },
    {
      source: require('../../assets/imgs/community/med-3.png'),
    },
    {
      source: require('../../assets/imgs/community/med-4.png'),
    },
    {
      source: require('../../assets/imgs/community/med-5.png'),
    },
    {
      source: require('../../assets/imgs/community/med-1.png'),
    },
  ];
  return (
    <Container maxWidth={'lg'}>
      <Box
        container
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          maxWidth: '100%',
          overflow: 'hidden',
        }}>
        {medallas.map((medalla, idx) => {
          return (
            <img
              src={medalla.source}
              alt={'medalla'}
              style={{
                width: 'auto',
                maxWidth: '15%',
              }}
            />
          );
        })}
      </Box>
      <div className={'comms-container'}>
        <Container maxWidth={'lg'}>
          <img
            src={require('../../assets/imgs/community/zoorbis-all.png')}
            alt={'comm-1'}
            style={{
              maxWidth: '100%',
              height: 'auto',
              maxHeight: '250px',
            }}
          />
          <Grid container className={'comms-nft'}>
            <Grid
              item
              sm={12}
              md={6}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <img
                style={{
                  maxWidth: '100%',
                  maxHeight: '405px',
                  marginLeft: 20,
                  marginRight: sm ? 0 : 50,
                }}
                src={require('../../assets/imgs/community/nft_banner.png')}
                alt={'NFTs'}
              />
            </Grid>

            <Grid
              item
              sm={12}
              md={6}
              style={{
                width: '100%',
              }}>
              <Grid
                container
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: sm ? '20px' : '0px',
                }}>
                <Grid item xs={12}>
                  <p className={'comms-headline'}>
                    Do you want to be part of the
                    <br />
                    Zoorbis community?
                  </p>
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{
                    width: '100%',
                  }}>
                  <Grid
                    container
                    className={'comms-timer-container'}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      maxWidth: '100%',
                      overflow: 'hidden',
                    }}>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        maxWidth: '100%',
                      }}>
                      <div className={'comms-timer-sqr'}>
                        <label className={'comms-timer-num'}>{days}</label>
                        <br />
                        <label className={'comms-timer-text'}>Days</label>
                      </div>

                      <div className={'comms-timer-sqr'}>
                        <label className={'comms-timer-num'}>{hours}</label>
                        <br />
                        <label className={'comms-timer-text'}>Hours</label>
                      </div>

                      <div className={'comms-timer-sqr'}>
                        <label className={'comms-timer-num'}>{minutes}</label>
                        <br />
                        <label className={'comms-timer-text'}>Minutes</label>
                      </div>

                      <div className={'comms-timer-sqr'}>
                        <label className={'comms-timer-num'}>{seconds}</label>
                        <br />
                        <label className={'comms-timer-text'}>Seconds</label>
                      </div>
                    </Box>

                    <Button
                      disableElevation={true}
                      variant="contained"
                      className={'cmint-button'}
                      onClick={() => {
                        window.open('https://mint.zoorbis.com/', '_blank');
                      }}>
                      Mint now
                    </Button>
                    <Box className={'intro-txt'} style={{ marginTop: '0.5rem' }}>
                      <span>Test our functions on testnet before launch</span>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </Container>
  );
};

export default CommunityHub;

import react, {useState, useEffect} from "react";
import './whitelist.css'
import {Button, Container, Grid, Typography, useMediaQuery} from "@mui/material";

const WListComp = () => {

    const sm = useMediaQuery('(max-width:899px)');

    return (
        <div
            className={"wlist-container"}
        >
            <Container
                maxWidth={'lg'}
            >
                <Grid
                    container
                    className={'wlist-text'}
                    style={{
                        justifyContent: sm ? 'center' : 'flex-start',
                    }}
                >
                    <Grid
                        item sm={12} md={6}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                    <Typography
                        style={{
                            fontSize: sm ? '1.5rem' : '2rem',
                            fontWeight: '800',
                        }}
                    >
                        Be part of our whitelist
                    </Typography>
                    </Grid>

                    <Grid item sm={12} md={6}>
                        <Button
                            variant="contained"
                            className={'wlist-button'}
                            style={{
                                marginTop: sm ? '20px' : '0px',
                            }}
                        >
                            Participate
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default WListComp;

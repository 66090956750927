import React from "react";
import "./token.css";
import {Box, Container, useMediaQuery} from "@mui/material";
import TokenComponent from "./TokenComponent";
import AliceCarousel from "react-alice-carousel";
import {Circle} from "@mui/icons-material";

const handleDragStart = (e) => e.preventDefault();

const renderDotsItem = ({ isActive }) => {
    return isActive ?
        <Circle
            style={{
                fontSize: '1.0rem',
                color: '#E99F00',
                marginRight: '20px',
            }}
        />
        :
        <Circle
            style={{
                fontSize: '1.0rem',
                color: '#fff',
                marginRight: '20px',
            }}
        />
};

const responsiveCarousel = {
    0: {items: 1},
    899: {items: 1},
    900: {items: 2},
    1200: {items: 2},
    1400: {items: 2},
}

const items = [
    <TokenComponent
        title={'ERC-20'}
        subtitle={'Native token'}
        tokenImg={require('../../assets/imgs/token-1.png')}
        tokenName={'ZOO'}
        tokenDescription={'Zoorbis token'}
        onDragStart={handleDragStart}
    />,
    <TokenComponent
        title={'ERC-721'}
        subtitle={'NFT Asset'}
        tokenImg={require('../../assets/imgs/token-2.png')}
        tokenName={'Zoorbis'}
        tokenDescription={'(NFT Assets)'}
        onDragStart={handleDragStart}
    />,
];

const Token = () => {

    const sm = useMediaQuery('(max-width:899px)');

    return (
        <Box
            className="token-container"
            style={{
                padding: sm ? '50px 20px 50px 20px' : '50px 0px 50px 0px',
            }}
        >
            <Container
                maxWidth={'lg'}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <h1
                    style={{
                        fontSize: sm ? '30px' : '40px',
                        fontWeight: 'bold',
                        margin: 0,
                    }}
                >
                    TOKEN & ASSETS
                </h1>

                <p
                    style={{
                        width: sm ? '100%' : '50%',
                        marginTop: '0px',
                        marginBottom: '50px',
                    }}
                >
                    The Zoorbis economy consists of 2 main assets.
                </p>

                <AliceCarousel
                    items={items}
                    responsive={responsiveCarousel}
                    mouseTracking={sm}
                    autoPlay={true}
                    autoPlayInterval={sm ? 5000 : 10000}
                    infinite
                    renderDotsItem={renderDotsItem}
                />
            </Container>
        </Box>
    );
}

export default Token;

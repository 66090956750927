import React from "react";
import "./partners.css";
import { Container, Grid, useMediaQuery } from "@mui/material";

const Partners = () => {
  const sm = useMediaQuery("(max-width:899px)");

  return (
    <div className={"partners-container"}>
      <Container
        maxWidth={"lg"}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1
          style={{
            fontSize: "2.2rem",
            fontWeight: "800",
            marginTop: "30px",
            marginBottom: "0px",
            letterSpacing: "0.1rem",
            color: "#3A0B69",
            textTransform: "uppercase",
          }}
        >
          Our Partners
        </h1>

        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            marginTop: 50,
            marginBottom: 50,
          }}
        >
          <Grid
            item
            sm={12}
            md={4}
            style={{
              width: "100%",
              borderRadius: "10px",
              background: "#EAEBF3",
              boxShadow: "8px -8px 15px #d3d4db, -8px 8px 15px #ffffff",
              padding: "20px",
            }}
          >
            <a href={"https://www.latamglobalschool.com"} target="_blank">
              <img
                src={require("../../assets/imgs/partners/partner-1.png")}
                alt={"partner-1"}
              />
            </a>
          </Grid>

          <Grid
            item
            sm={12}
            md={4}
            style={{
              width: "100%",
              marginTop: sm ? "50px" : "0px",
              borderRadius: "10px",
              background: "#EAEBF3",
              boxShadow: "8px -8px 15px #d3d4db, -8px 8px 15px #ffffff",
              padding: "30px",
            }}
          >
            <a href="https://cyorstudios.com" target="_blank">
              <img
                src={require("../../assets/imgs/partners/partner-2.png")}
                alt={"partner-2"}
              />
            </a>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Partners;

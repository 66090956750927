import "./App.css";
import Header from "./components/Header";
import { Container } from "@mui/material";
import Footer from "./components/Footer";
import IntroInfo from "./components/Intro";
import CommunityHub from "./components/Community";
import WListComp from "./components/Whitelist";
import VideoPlayer from "./components/VideoPlayer";
import FirstInsight from "./components/FirstInsight";
import Token from "./components/Token";
import Team from "./components/Team";
import Partners from "./components/Partners";
import Support from "./components/Support";
import Copyright from "./components/Copyright";
import Roadmap from "./components/Roadmap";

function App() {
  return (
    <div className="App">
      <Header />
      <IntroInfo />
      <CommunityHub />
      <WListComp />
      <VideoPlayer />
      <FirstInsight />
      <Roadmap />
      <Team />
      <Partners />
      <Support />
      <Footer />
      <Copyright />
    </div>
  );
}

export default App;

import React, {useEffect, useState} from 'react';
import {Box, Button, Container, Grid, IconButton, Typography} from "@mui/material";
import "./header.css";
import {MenuOutlined} from "@mui/icons-material";

const Header = () => {

    let menuItems = null;
    let menuContainer = null;

    useEffect(() => {
        menuItems = document.querySelector('#overlay');
        menuContainer = document.querySelector('.menu-container');
    })

    const buttonsInfo = [
        {
            name: "Home",
            link: "https://zoorbis.com/"
        },
        {
            name: "Mint now!",
            link: "https://mint.zoorbis.com/"
        },
        {
            name: "Ecosystem",
            link: "https://staking.zoorbis.com/"
        },
        {
            name: "Whitepaper",
            link: "https://docs.zoorbis.com/"
        }
    ];

    return (
        <div
            className="container-header"
        >
            <Container>
                <div className={'header-back-1'}/>
                <div className={'header-back-2'}/>
                <Grid
                    container
                    className={'header'}
                >
                    <Grid
                        item xs={12} md={2}
                        className={'header'}
                    >
                        <img
                            src={require('../../assets/imgs/logo.png')}
                            alt="logo"
                            className={'logo-header'}
                        />
                    </Grid>

                    {
                        buttonsInfo.map((button, index) => {
                            return (
                                <Grid
                                    item xs={12} md={2}
                                    key={index}
                                    className={'header'}
                                    style={{
                                        padding: 10
                                    }}
                                >
                                    <Button
                                        className={'button-header'}
                                        variant={'contained'}
                                        href={button.link}
                                    >
                                        {button.name}
                                    </Button>
                                </Grid>
                            );
                        })
                    }

                    <Grid
                        item xs={12} md={2}
                        className={'header'}
                        style={{
                            paddingLeft: 20
                        }}
                    >
                        <div
                            className={'button-mint'}
                            onClick={() => {
                                window.open('https://staking.zoorbis.com/', '_blank');
                            }}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <img
                                src={require('../../assets/imgs/controlador-de-videojuegos.png')}
                                alt="logo"
                                style={{
                                    width: 30,
                                    height: 30,
                                    objectFit: 'contain',
                                    marginRight: 10
                                }}
                            />
                            <Typography>
                                START<br/>EARNING!
                            </Typography>
                        </div>
                    </Grid>
                </Grid>

                <Grid
                    container
                    className={'header mobile'}
                >
                    <Grid
                        item sm={2} xs={6}
                        className={'header mobile'}
                    >
                        <img
                            src={require('../../assets/imgs/logo.png')}
                            alt="logo"
                            className={'logo-header'}
                        />
                    </Grid>
                </Grid>

                <IconButton
                    className={'menu'}
                    onClick={(e) => {
                        menuItems.classList.toggle('open');
                        menuContainer.classList.toggle('full-menu');
                    }}
                >
                    <MenuOutlined
                        fontSize={'large'}
                        color={'primary'}
                        style={{
                            color: 'white',
                            fontSize: '3.5rem'
                        }}
                    />
                </IconButton>

                <div className="menu-container" id="toggle">
                </div>

                <div className="overlay" id="overlay">
                    <nav className="overlay-menu">
                        <ul>
                            {
                                buttonsInfo.map((button, index) => {
                                    return (
                                        <li key={index}>
                                            <a
                                                href={button.link}
                                                target={'_blank'}
                                            >
                                                {button.name}
                                            </a>
                                        </li>
                                    );
                                })
                            }
                            <li
                                style={{
                                    border: '1px solid white',
                                    marginTop: '20px',
                                    width: 'auto',
                                }}
                            >
                                <a
                                    href={'#'}
                                    onClick={() => {
                                        window.open('https://staking.zoorbis.com/', '_blank');
                                    }}
                                >
                                    START EARNING!
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </Container>
        </div>
    )
        ;
}

export default Header;

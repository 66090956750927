import React from "react";

const Copyright = () => {
    return (
        <div
            style={{
                textAlign: "center",
                backgroundColor: 'white',
                color: 'black',
                padding: '10px',
            }}
        >
            <p
                style={{
                    fontSize: "1.0rem",
                    margin: "0",
                    fontWeight: "600",
                }}
            >
                &copy; {new Date().getFullYear()} Copyright{" "}
                <a
                    href="https://www.zoorbis.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        textDecoration: "none",
                        color: "#2996FF",
                    }}
                >
                    Zoorbis
                </a>
                {". "}All rights reserved.
            </p>
        </div>
    );
}

export default Copyright;

import React from "react";
import "./roadmap.css";
import { Box, Container, useMediaQuery } from "@mui/material";
import "react-alice-carousel/lib/alice-carousel.css";
import RoadmapImage from "../../assets/imgs/road_entrega_01.png";

const Roadmap = () => {
  const sm = useMediaQuery("(max-width:899px)");

  return (
    <Box className={"roadmap-container"}>
      <Container
        maxWidth={"lg"}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <h1
          style={{
            fontSize: "2.2rem",
            fontWeight: "800",
            marginTop: "30px",
            marginBottom: "0px",
            letterSpacing: "0.1rem",
            color: "#3A0B69",
            textTransform: "uppercase",
          }}
        >
          Roadmap
        </h1>

        <Box className={"roadmap-content"}>
          <img
            src={RoadmapImage}
            alt={"Roadmap"}
            style={{
              maxWidth: "100%",
              width: "100%",
              height: "auto",
            }}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default Roadmap;

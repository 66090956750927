import react from "react";
import {Grid} from "@mui/material";
import "./footer.css";

const Footer = () => {
    return (
        <div className="footer-container">
            <Grid
                container
                className={'footer-grid-container'}
            >
                <Grid
                    item xs={12} md={3}
                    className="footer-item first"
                >
                    <img
                        src={require('../../assets/imgs/logo.png')}
                        alt="logo"
                        className="logo-footer"
                    />
                </Grid>

                <Grid
                    item xs={12} md={3}
                    className="footer-item privacy second"
                >
                    <a
                        href="#"
                        target={'_blank'}
                    >
                        Terms of use
                    </a>
                    <a
                        href="#"
                        target={'_blank'}
                    >
                        Privacy Policy
                    </a>
                    <a
                        href="#"
                        target={'_blank'}
                    >
                        FAQ
                    </a>
                </Grid>

                <Grid
                    item xs={12} md={3}
                    className="footer-item logos third"
                >
                    <p>
                        JOIN OUR COMMUNITY
                    </p>
                    <div
                        className="footer-logos-container"
                    >
                        <a
                            href="https://www.facebook.com/zoorbis"
                            target={'_blank'}
                            className={'footer-link-logo'}
                        >
                            <img
                                src={require('../../assets/imgs/facebook-logo.png')}
                                alt="facebook-logo"
                            />
                        </a>

                        <a
                            href="https://www.instagram.com/zoorbisnft/?hl=es-la"
                            target={'_blank'}
                            className={'footer-link-logo'}
                        >
                            <img
                                src={require('../../assets/imgs/instagram-logo.png')}
                                alt="instagram-logo"
                            />
                        </a>

                        <a
                            href="https://twitter.com/zoorbis"
                            target={'_blank'}
                            className={'footer-link-logo'}
                        >
                            <img
                                src={require('../../assets/imgs/twitter-logo.png')}
                                alt="twitter-logo"
                            />
                        </a>

                        <a
                            href="https://discord.gg/9ktUzyBPJG"
                            target={'_blank'}
                            className={'footer-link-logo'}
                        >
                            <img
                                src={require('../../assets/imgs/discord-logo.png')}
                                alt="discord-logo"
                            />
                        </a>

                        <a
                            href="https://www.youtube.com/channel/UC3DQTRJuztrnVx7YX30lYBA"
                            target={'_blank'}
                            className={'footer-link-logo'}
                        >
                            <img
                                src={require('../../assets/imgs/youtube-logo.png')}
                                alt="youtube-logo"
                            />
                        </a>

                        <a
                            href="https://t.me/+gcQ7b0nPJWNlZDFh"
                            target={'_blank'}
                            className={'footer-link-logo'}
                        >
                            <img
                                src={require('../../assets/imgs/telegram-logo.png')}
                                alt="telegram-logo"
                            />
                        </a>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default Footer;

import React from "react";
import "./team.css";
import { Container, useMediaQuery } from "@mui/material";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import TeamMember from "./TeamMember";

const responsiveCarousel = {
  0: { items: 1 },
  899: { items: 1 },
  900: { items: 2 },
  1200: { items: 3 },
  1400: { items: 4 },
};

const items = [
  <TeamMember
    name={"José Crespo"}
    imgParam={"Cheko0.png"}
    description={"Graphic designer"}
  />,
  <TeamMember
    name={"Daniel Reatiga"}
    imgParam={"Daniel0.png"}
    description={"Game Performance Manager"}
  />,
  <TeamMember
    name={"Evelyn Torres"}
    imgParam={"Evelyn0.png"}
    description={"Advisor CEO LATAM Global School"}
  />,
  <TeamMember
    name={"Fabian Moreno"}
    imgParam={"Fabian0.png"}
    description={"Game Designer"}
  />,
  <TeamMember
    name={"Enrique Restrepo"}
    imgParam={"Kike0.png"}
    description={"Marketing"}
  />,
  <TeamMember
    name={"María Fernanda Latorre"}
    imgParam={"Maria0.png"}
    description={"Art Leader"}
  />,
  <TeamMember
    name={"Javier Landeros"}
    imgParam={"Javier0.png"}
    description={"3D Artist"}
  />,
  <TeamMember
    name={"Ruben Mendoza"}
    imgParam={"Ruben0.png"}
    description={"2D Artist"}
  />,
];

const TeamComponent = () => {
  const sm = useMediaQuery("(max-width:899px)");

  return (
    <div
      className={"team-container"}
      style={{
        padding: sm ? "50px 20px 50px 20px" : "50px 0px 50px 0px",
      }}
    >
      <Container
        maxWidth={"lg"}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1
          style={{
            fontSize: "2.2rem",
            fontWeight: "bold",
            margin: "0 0 20px 0",
          }}
        >
          TEAM
        </h1>

        <AliceCarousel
          id={"team-carousel"}
          items={items}
          responsive={responsiveCarousel}
          mouseTracking={sm}
          autoPlay={true}
          autoPlayInterval={3000}
          infinite
        />
      </Container>
    </div>
  );
};

export default TeamComponent;
